<!-- LoginForm.svelte -->
<script lang="ts"> 
  import { goto } from "$app/navigation";
  import { createEventDispatcher } from "svelte";
  import { getContextClient } from "@urql/svelte";
  import {  setTokens, clearTokens } from '$lib/stores/authStore'; 
	import Input from '$theme/components/form/Input.svelte';
  import { writable } from 'svelte/store';
	import { handleGqlErr } from "$lib/utils/gqlfx";
	import alerts from "$lib/stores/alerts";
  import { LoginUser, RegisterUser } from "$lib/gql/user";
  import { PATH } from '$lib/enums/path';
  import { authUser } from '$lib/stores/app';
  import graph   from "$theme/images/graph.png";

  let busy = false;
  let errors = { benefits: "", description: "" };
  let valid = false;

  const dispatch = createEventDispatcher();
  const client = getContextClient();
  let email="";
  let password="";
  
  async function handleSubmit() {
    //Add Code for graphql

    let title = "Login Member";
    if (busy) return;
    busy = true;
   
    const res = await client
      .mutation(LoginUser, {  
        email: email,
        password: password,  
      })
      .toPromise();
 
    console.log("RES", res);
    busy = false;

    console.log("ERROR", res.error);
    if (res.error) {
      let errMsg = handleGqlErr(res.error);
      alerts.error(title, errMsg);
      return false;
    }

    let result = res.data.loginAuth;
 
    if (result) {
      alerts.success(title,"Login Successfully!!"); 
      setTokens(result.token, result.refreshToken);
      authUser.set(result?.user);
      goto(PATH.MY_PROFILE);
      
      console.log("token",result.token,"refreshToken", "refreshExpiresIn",result.expiresIn )
      return;
    }
    //End Graphql
  } 
</script>

<div class="flex w-screen flex-wrap text-slate-800">
  <div class="flex w-full flex-col md:w-1/2">
    <div class="flex justify-center pt-12 md:justify-start md:pl-12">
      <a href="#" class="text-2xl font-bold text-blue-600"> SPW911 . </a>
    </div>
    <div class="my-auto mx-auto flex flex-col justify-center px-6 pt-8 md:justify-start lg:w-[28rem]">
      <p class="text-center text-3xl font-bold md:leading-tight md:text-left md:text-5xl">
        Welcome back <br />
        to <span class="text-blue-600">SPW911</span>
      </p>
      <p class="mt-6 text-center font-medium md:text-left">Sign in to your account below.</p>

      <form class="flex flex-col items-stretch pt-3 md:pt-8" on:submit|preventDefault={handleSubmit} > 
        <div>
          <Input 
          type="email"
          name="email"
          label="Email"
          required
          placeholder="name@company.com" 
          labelClass="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          cls="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          bind:value={email}
        />
      </div>
        <div>
          <Input 
          type="password"
          name="password"
          label="Password"
          required
           placeholder="••••••••"
          labelClass="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          cls="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          bind:value={password}
        />
          
        </div>
        <a href="{PATH.FORGET_PASSWORD}" class="mb-6 text-center text-sm font-medium text-gray-600 md:text-left">Forgot password?</a>
        <button type="submit" class="rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-32">Sign in</button>
      </form>
      <div class="py-12 text-center">
        <p class="text-gray-600">
          Don't have an account?
          <a href="{PATH.REGISTER}" class="whitespace-nowrap font-semibold text-gray-900 underline underline-offset-4">Sign up for free.</a>
        </p>
      </div>
    </div>
  </div>
  <div class="relative hidden h-screen select-none bg-blue-600 bg-gradient-to-br md:block md:w-1/2">
    <div class="py-16 px-8 text-white xl:w-[40rem]">
      <span class="rounded-full bg-white px-3 py-1 font-medium text-blue-600">New Feature</span>
      <p class="my-6 text-3xl font-semibold leading-10">Create animations with <span class="abg-white whitespace-nowrap py-2 text-cyan-300">drag and drop</span>.</p>
      <p class="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt necessitatibus nostrum repellendus ab totam.</p>
      <a href="#" class="font-semibold tracking-wide text-white underline underline-offset-4">Learn More</a>
    </div>
    <img class="ml-8 w-11/12 max-w-lg rounded-lg object-cover" src="{graph}" />
  </div>
</div> 